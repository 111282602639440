// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-shapeable-gatsby-theme-shapeable-web-src-components-404-tsx": () => import("./../../../node_modules/@shapeable/gatsby-theme-shapeable-web/src/components/404.tsx" /* webpackChunkName: "component---node-modules-shapeable-gatsby-theme-shapeable-web-src-components-404-tsx" */),
  "component---src-gatsby-entities-catalyst-tsx": () => import("./../../../src/gatsby/entities/catalyst.tsx" /* webpackChunkName: "component---src-gatsby-entities-catalyst-tsx" */),
  "component---src-gatsby-entities-commodity-group-tsx": () => import("./../../../src/gatsby/entities/commodity-group.tsx" /* webpackChunkName: "component---src-gatsby-entities-commodity-group-tsx" */),
  "component---src-gatsby-entities-innovation-tsx": () => import("./../../../src/gatsby/entities/innovation.tsx" /* webpackChunkName: "component---src-gatsby-entities-innovation-tsx" */),
  "component---src-gatsby-entities-page-tsx": () => import("./../../../src/gatsby/entities/page.tsx" /* webpackChunkName: "component---src-gatsby-entities-page-tsx" */),
  "component---src-gatsby-entities-post-tsx": () => import("./../../../src/gatsby/entities/post.tsx" /* webpackChunkName: "component---src-gatsby-entities-post-tsx" */),
  "component---src-gatsby-entities-topic-tsx": () => import("./../../../src/gatsby/entities/topic.tsx" /* webpackChunkName: "component---src-gatsby-entities-topic-tsx" */),
  "component---src-gatsby-entities-trend-tsx": () => import("./../../../src/gatsby/entities/trend.tsx" /* webpackChunkName: "component---src-gatsby-entities-trend-tsx" */)
}

